.MemberList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 80%;
  border: 1px solid #e5eaf5;
  border-radius: 10px;
  font-weight: 250;
  background-color: #e5eaf5;
  box-sizing: border-box;
  overflow: scroll;
  padding-top: 20px;
}

.MemberEntry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 70px;
  overflow: auto;
  border: 1px solid #c9d3dd;
  border-radius: 10px;
  background: whitesmoke;
  margin: 5px;
  color: #274ba0;
  font-family: "Oswald";
  font-size: 1.2rem;
  font-weight: 700;
  padding-left: 20px;
  box-sizing: border-box;
}

.BackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  color: #ffffff;
  /* background: #1180f1;
  border-color: #1180f1; */
  background: grey;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
}

.BackButton:hover {
  transform: translateY(2px);
}
