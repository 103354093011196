.ModalBackground {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.651);
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(max(500px, 33vw));
  height: 35%;
  background: rgba(255, 255, 255, 0.945);
  border-radius: 10px;
  text-align: center;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "Oswald";
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 25%;
}
