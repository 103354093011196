.RoomContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 150px);
  background-color: whitesmoke;
}

.ContentWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 95%;
  box-sizing: border-box;
}

.ModalContent {
  text-align: center;
}

.ModalTitle {
  font-size: 30px;
  font-weight: 300;
}

.RoomNameInput {
  margin: 30px auto;
  width: 400px;
  height: 40px;
  border: 1px solid #c9d3dd;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Oswald";
  font-size: 50px;
  width: 95%;
  height: 15%;
  background-color: whitesmoke;
  background-color: #e5eaf5;
  color: #25408e;
  border-radius: 10px;
}
