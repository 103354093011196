.VideoConferenceRoom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
}

.LogoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 150px;
  padding-left: 40px;
  box-sizing: border-box;
}

.Content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: calc(100% - 150px);
  box-sizing: border-box;
}

.LeftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}

.RightSide {
  position: relative;
  display: grid;
  width: 50%;
  height: 98%;
  margin-left: 15px;
  margin-bottom: 15px;
  background-color: whitesmoke;
  border-radius: 10px;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "1 2"
    "3 4"
    "5 6"
    "7 8";
  box-sizing: border-box;
}

.Invite {
  border: 30px solid blue;
  box-sizing: border-box;
}

.PeerVideo {
  position: relative;
  width: 100%;
  height: 100%;
  border: 10px solid hotpink;
  box-sizing: border-box;
}

.RightSide:nth-child(1) {
  grid-row: 1/2;
  grid-column: 1/2;
}

.RightSide:nth-child(2) {
  grid-row: 1/2;
  grid-column: 2/3;
}

.RightSide:nth-child(3) {
  grid-row: 2/3;
  grid-column: 1/2;
}

.RightSide:nth-child(4) {
  grid-row: 2/3;
  grid-column: 2/3;
}

.RightSide:nth-child(5) {
  grid-row: 3/4;
  grid-column: 1/2;
}

.RightSide:nth-child(6) {
  grid-row: 3/4;
  grid-column: 2/3;
}

.RightSide:nth-child(7) {
  grid-row: 4/5;
  grid-column: 1/2;
}

.RightSide:nth-child(8) {
  grid-row: 4/5;
  grid-column: 2/3;
}

.CanvasOnVideo {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70%;
}

.MenuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 30%;
}

.RightSideInnerOnMode {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: whitesmoke;
  z-index: 3;
}

video {
  border-radius: 15px;
}

.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
