.MyRoom {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 85%;
  font-size: 20px;
  text-align: center;
  box-sizing: border-box;
}

.RoomsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 80%;
  border: 1px solid #e5eaf5;
  border-radius: 10px;
  font-weight: 250;
  background-color: whitesmoke;
  background-color: #e5eaf5;
  box-sizing: border-box;
  overflow: scroll;
  padding-top: 50px;
}

.Title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 10%;
  font-size: 2em;
  margin: 5px;
  box-sizing: border-box;
}

.RoomNameInput {
  width: 400px;
  height: 40px;
  border: 1px solid #c9d3dd;
  background: red;
  border-radius: 10px;
  text-align: center;
}

.AddRoomButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  background: #274ba0;
  color: #ffffff;
  border-color: #274ba0;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

.AddRoomButton:hover {
  transform: translateY(2px);
}
