.SideBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 20vw;
  height: 150px;
  background-color: whitesmoke;
  /* border: 1px solid black; */
}

.MenuBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
  color: #25408e;
  font-family: "Oswald";
  /* background-color: #c0c9e8; */
}

.Menu:hover {
  /* color: #ffffff;
  background-color: #1a3064; */
  transform: translateY(2px);
}
