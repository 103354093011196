.Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.Video {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.Canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
