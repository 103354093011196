.MenuButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 15px;
  background-color: #e5eaf5;
  border: 1px solid #e5eaf5;
}

.MenuButton:hover {
  background-color: #274ba0;
  border: 1px solid #274ba0;
  color: white;
}

.MenuButton:last-child {
  background-color: #7f0000;
  color: white;
}

.MenuButton:last-child:hover {
  background-color: #4e0000;
  color: white;
}

.MenuTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-weight: 800;
  width: 100%;
  font-size: 10px;
  padding: 0;
  margin-bottom: -10px;
}

i {
  font-size: 36px;
}
