.CompleteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background: #274ba0;
  color: #ffffff;
  border-color: #274ba0;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}

.CompleteButton:hover {
  transform: translateY(2px);
}
