.GroupList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 80%;
  border: 1px solid #e5eaf5;
  border-radius: 10px;
  font-weight: 250;
  background-color: #e5eaf5;
  box-sizing: border-box;
  overflow: scroll;
  padding-top: 20px;
}

.GroupEntry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 70px;
  overflow: auto;
  border: 1px solid #c9d3dd;
  border-radius: 10px;
  background: whitesmoke;
  margin: 5px;
  color: #274ba0;
  font-family: "Oswald";
  font-size: 1.2rem;
  padding-left: 20px;
  box-sizing: border-box;
}

.GroupEntry:hover {
  background: #ffdc3f;
  color: rgb(49, 45, 5);
}

.GroupName {
  font-size: 1em;
  font-weight: 700;
}
