.RoomEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 70px;
  font-weight: 500;
  border: 1px solid #c9d3dd;
  border-radius: 10px;
  background: whitesmoke;
  color: #274ba0;
  font-family: "Oswald";
  box-sizing: border-box;
  margin: 5px;
}

.RoomEntry:hover {
  background: #ffdc3f;
  color: rgb(49, 45, 5);
}

.RoomName {
  display: flex;
  flex-direction: row;
  justify-self: start;
  align-items: center;
  width: 70%;
  height: 90%;
  box-sizing: border-box;
  margin: 20px;
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30%;
  height: 90%;
}
