.GroupContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 150px);
  background-color: whitesmoke;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 95%;
  box-sizing: border-box;
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Oswald";
  font-size: 50px;
  width: 95%;
  height: 15%;
  background-color: whitesmoke;
  background-color: #e5eaf5;
  color: #25408e;
  border-radius: 10px;
}

.MemberTitleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.BackButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
}

.BackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 140px;
  height: 40px;
  color: #ffffff;
  background: grey;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}

.BackButton:hover {
  transform: translateY(2px);
  background-color: black;
}

.MemberTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
  height: 100%;
  padding-left: 15%;
  box-sizing: border-box;
}

.Group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85%;
  box-sizing: border-box;
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 10%;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #e5eaf5;
  border: 1px solid #e5eaf5;
  border-radius: 10px;
  margin-bottom: 10px;
}

.AddButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background: #274ba0;
  color: #ffffff;
  border-color: #274ba0;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
}

.AddButton:hover {
  transform: translateY(2px);
}

.DeleteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background: #7f0000;
  color: #ffffff;
  border-color: #7f0000;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
}

.DeleteButton:hover {
  transform: translateY(2px);
}

.Input {
  margin: 10px auto;
  width: 85%;
  height: 30px;
  border: 1px solid #c9d3dd;
  background: #ffffff;
  text-align: center;
}

.AddedMembers {
  overflow: auto;
  margin: 10px auto;
  width: 20px;
  height: 20px;
  border: 1px solid #c9d3dd;
  background: #ffffff;
  border-radius: 50%;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ModalTitle {
  font-size: 30px;
}

.Members {
  border: 1px solid #8e8e93;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  width: 90%;
  height: 30%;
  overflow: auto;
}

.AddedMemberListTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  border-bottom: 1px solid #8e8e93;
  width: 100%;
  height: 25%;
}

.PlusButton {
  width: 20px;
  height: 20px;
}

.Member:last-child {
  overflow-anchor: auto;
}
