.Home {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 150px);
  min-height: 500px;
  /* background-color: #e5eaf5; */
  background-color: whitesmoke;
  font-family: "Oswald";
}

.ServiceDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 25vw;
  min-height: calc(min(100vh, 400px));
  font-size: 2.5rem;
  color: #1a3064;
}

.ServiceImage {
  width: 40vw;
  min-height: calc(min(100vh, 400px));
}

.NoteBook {
  width: 100%;
  height: 100%;
  border-radius: 3%;
  /* box-shadow: -10px 30px 25px grey; */
}
