.SettingModalBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.651);
}

.SettingModal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: calc(max(700px, 50vw));
  height: 70%;
  border-radius: 10px;
  background-color: whitesmoke;
}

.VideoWrapper {
  position: relative;
  width: 95%;
  height: 70%;
}

.Video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}

.Mute {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 50px;
  font-family: "Oswald";
  font-size: 1.5rem;
  font-weight: 700;
}

.Checkbox {
  margin-right: 10px;
}
