.Logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-shadow: -5px 5px 5px grey;
  color: white;
  letter-spacing: 0px;
  font-family: "Anton", Arial, sans-serif;
  user-select: none;
  font-size: 45px;
  transition: all 0.05s ease-out;
  text-decoration: none;
  width: 260px;
  height: 150px;
}

.LogoImage {
  height: 100%;
  mix-blend-mode: multiply;
  filter: saturate(1);
}

.letter:hover {
  transform: translateY(1px);
  text-shadow: -4px 5px 4px grey;
}
