.ButtonInHeader {
  display: flex;
  flex-direction: row;
  flex-direction: center;
  align-items: center;
}

.Button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  background: #274ba0;
  color: #ffffff;
  border-color: #274ba0;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 20px 20px;
  font-family: "Oswald";
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  /* box-shadow: 0px 5px 10px grey; */
}

.Button:hover {
  transform: translateY(2px);
  /* box-shadow: 0px 4px 5px grey; */
}
