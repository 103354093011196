.Header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 150px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: whitesmoke;
}
.MenuInHeder {
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

* {
  text-decoration: none;
}
