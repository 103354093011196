.GroupListInVideoRoom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.Title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 10%;
  font-family: "Oswald";
  font-size: 2rem;
  font-weight: 800;
}

.SendMailButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10%;
  box-sizing: border-box;
  padding-right: 15px;
}

.GroupListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 75%;
  /* border: 1px solid grey; */
  border-radius: 5px;
}

.GroupEntry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 10%;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
  background-color: #e5eaf5;
  font-family: "Oswald";
  border-radius: 5px;
}

.GroupEntry:hover {
  background-color: #274ba0;
  color: white;
}

.GroupLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  font-size: 1em;
  font-weight: 700;
}

.MemberList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  font-family: "Oswald";
  font-size: 0.8rem;
  background-color: #1a3064;
  color: white;
}

.MemberEntry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
}

.NoGroupDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 30%;
  font-family: "Oswald";
}
