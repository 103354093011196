.Wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.Host {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.Peer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.Canvas {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
