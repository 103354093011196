.MenuBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  height: 60%;
  background-color: #e0e2e1;
  border-radius: 30px;
  box-sizing: border-box;
}

.MenuBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 15px;
}

.Muted {
  background-color: grey;
}

.Muted:hover {
  background-color: green;
}

.MicOn {
  background-color: green;
}

.MicOn:hover {
  background-color: grey;
}

.MenuTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-weight: 800;
  width: 100%;
  font-size: 10px;
  padding: 0;
  margin-bottom: -10px;
}

i {
  font-size: 36px;
}
